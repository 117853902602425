import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import AnyLink from "../components/hoc/anyLink/AnyLink"

const NotFoundPage = () => {
  const i18n = useIntl().formatMessage

  return (
    <>
      <SEO title="404: Not found" />
      <section className="section-padding-big">
        <div className="container">
          <div className="row text-center center-xs">
            <div className="col col-xs-12 col-md-6">
              <h1>{ i18n({ id: "pages.404.title" })}</h1>
              <p>{ i18n({ id: "pages.404.description" })}</p>
              <AnyLink className="btn space-right" to="/">{ i18n({ id: "general.to_frontpage" })}</AnyLink>
              <AnyLink className="text-green text-bold" to="/login">{ i18n({ id: "general.login" })} →</AnyLink>
            </div>
          </div>
        </div>
      </section>

    </>
    )
}

export default NotFoundPage
